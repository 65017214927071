// ** Next Imports
import Image from "next/image";
import Link from "next/link";

// ** React Imports
import { useState } from "react";

// ** FA Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

// ** Image Imports
import logoImg from "../../../public/images/logo.png";
import hamburgerImg from "../../../public/images/hamburger.png";
import crossImg from "../../../public/images/cross.png";

// ** Style imports
import styles from "./styles/header.module.scss";

// ** Data Imports
import { CONTACT_INFO } from "../../helpers/DATA";

const ABOUT_PAGES: { [key: string]: string } = {
  "/installation-steps": "Installation Steps",
  "/residential-services": "Residential Services",
  "/referral-program": "Referral Program",
  "/quick-pay": "Quick Pay",
};

const Header = () => {
  const [menuDown, setMenuDown] = useState<boolean>(false);
  const menuItems = (
    <>
      <div className="menu-item">
        <Link className="menu-active" href="/">
          Home
        </Link>
      </div>
      <div className="menu-item">
        <Link href="/plans/">Plans</Link>
      </div>
      <div className="menu-item">
        <Link href="/check-availability/">Check Availability</Link>
      </div>
      <div className="menu-item">
        <Link href="/support">Support</Link>
      </div>
      <div className="menu-item">
        <Link href="#">Contact Us</Link>
        <ul className="sub-dropdown">
          <li>
            <a href={`tel:${CONTACT_INFO.call.number}`}>
              Call: {CONTACT_INFO.call.text}
            </a>
            <a href={`sms:${CONTACT_INFO.sms.number}`}>
              Text: {CONTACT_INFO.sms.text}
            </a>
            <a href={`mailto:${CONTACT_INFO.email}`}>
              Email: {CONTACT_INFO.email}
            </a>
          </li>
        </ul>
      </div>
      <div className="menu-item">
        <Link href="/about-us">
          About EIN <FontAwesomeIcon icon={faCaretDown} />
        </Link>
        <ul className="sub-dropdown">
          {Object.keys(ABOUT_PAGES).map((lnk, index) => (
            <li key={index}>
              <Link href={lnk}>{ABOUT_PAGES[lnk]}</Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <header className={styles.headerEl}>
      <div className="container">
        <div className={styles.headerRow}>
          <div className={styles.logo}>
            <Link href="/" passHref>
              <Image
                src={logoImg}
                className="img-fluid"
                placeholder="blur"
                alt="Logo"
                id="website-logo"
              />
            </Link>
          </div>
          <div className={styles.navbarHandler}>
            <Image
              src={menuDown ? crossImg : hamburgerImg}
              className="img-fluid"
              alt="Mobile Menu"
              onClick={() => {
                setMenuDown(!menuDown);
              }}
            />
          </div>
          <div className={styles.navbarCustom}>{menuItems}</div>
          {menuDown && <div>{menuItems}</div>}
        </div>
      </div>
    </header>
  );
};

export default Header;
