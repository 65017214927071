// ** Next Imports
import Link from "next/link";

// ** React imports
import { Row, Col } from "react-bootstrap";

// ** Style imports
import styles from "./styles/footer.module.scss";

// ** FA Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faPinterest,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faComments,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// ** Data Imports
import { CONTACT_INFO } from "../../helpers/DATA";

const ABOUT_PAGES: { [key: string]: string } = {
  "/privacy-policy": "Privacy Policy",
  "/terms-and-conditions": "Terms & Conditions",
  "/broadband-information": "Broadband Information",
  "/understanding-internet-speeds": "Understanding Internet Speeds",
  "/careers": "Careers",
};

const Footer = () => (
  <>
    <footer className={styles.footerEl}>
      <div className="container">
        <Row>
          <Col md={8} sm={6} xs={12}>
            <div className={styles.footerList}>
              <h3 className={styles.footerHeading1}>Get in touch.</h3>
              <ul>
                <li>
                  <a href={`tel:${CONTACT_INFO.call.number}`}>
                    <FontAwesomeIcon icon={faPhoneAlt} />{" "}
                    {CONTACT_INFO.call.text}
                  </a>
                </li>
                <li>
                  <a href={`sms:${CONTACT_INFO.sms.number}`}>
                    <FontAwesomeIcon icon={faComments} /> Text:{" "}
                    {CONTACT_INFO.sms.text}
                  </a>
                </li>
                <li>
                  <a href={`mailto:${CONTACT_INFO.email}`}>
                    <FontAwesomeIcon icon={faEnvelope} /> {CONTACT_INFO.email}
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={4} sm={6} xs={12}>
            <div className={styles.footerList}>
              <h3 className={styles.footerHeading1}>About EIN</h3>
              <ul>
                {Object.keys(ABOUT_PAGES).map((lnk, index) => (
                  <li key={index}>
                    <Link href={lnk}>{ABOUT_PAGES[lnk]}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </footer>

    <section className={styles.copyrightsSec}>
      <div className="container">
        <Row className={styles.equalCol}>
          <Col md={6} sm={8} xs={12}>
            <div className={styles.copyrightText}>
              <span>© Copyright Easy Internet Now, All Rights Reserved.</span>
            </div>
          </Col>
          <Col md={6} sm={4} xs={12}>
            <div className={styles.socialIcons1}>
              <a
                target="_blank"
                href="https://www.facebook.com/easyinternetnow/"
                rel="noopener noreferrer"
                aria-label="Visit out Facebook page"
              >
                <FontAwesomeIcon icon={faFacebookF} />{" "}
              </a>
              <a
                target="_blank"
                href="https://twitter.com/EasyInternetNow"
                rel="noopener noreferrer"
                aria-label="Visit out Twitter page"
              >
                <FontAwesomeIcon icon={faTwitter} />{" "}
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/easyinternetnow/"
                rel="noopener noreferrer"
                aria-label="Visit out Instagram page"
              >
                <FontAwesomeIcon icon={faInstagram} />{" "}
              </a>

              <a
                target="_blank"
                href="https://www.pinterest.com/easyinternetnow/"
                rel="noopener noreferrer"
                aria-label="Visit out Pinterest page"
              >
                <FontAwesomeIcon icon={faPinterest} />{" "}
              </a>

              <a
                target="_blank"
                href=" https://www.linkedin.com/company/easyinternetnow/"
                rel="noopener noreferrer"
                aria-label="Visit out Linkedin page"
              >
                <FontAwesomeIcon icon={faLinkedin} />{" "}
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  </>
);

export default Footer;
